import {
  doc,
  getDoc,
  getDocs,
  database,
  updateDoc,
  collection,
} from "../db/firebase";
import "./services.css";
import Swal from "sweetalert2";
import { Card, Row } from "reactstrap";
import Pricing from "components/prices";
import Topnav from "../components/Topnav";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import maddie from "../assets/unyte-maddie.png";
import ServiceButton from "../components/button";
import React, { useEffect, useCallback, useState } from "react";
import { Button, Box, Typography, CircularProgress } from "@mui/material";

function Services() {
  const navigate = useNavigate();
  const [fname, setFname] = useState("");
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isShowPrice, setShowPrice] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);

  const [selectedTv, setTv] = useState("");
  const [selectedPest, setPest] = useState("");
  const [selectedInternet, setInternet] = useState("");
  const [solarprovider, setSolarproviders] = useState("");
  const [securityprovider, setSecurityprovider] = useState("");

  const [tvPackage, setTvPackage] = useState("");
  const [pestPackage, setPestPackage] = useState("");
  const [internetPackage, setInternetPackage] = useState("");
  const [securityPackage, setSecurityPackage] = useState("");
  const [solarPackage, setSolarprovidersPackage] = useState("");

  const getServicesAndPackages = useCallback(async () => {
    let packagesList = [];
    let servicesList = [];
    let serviceCategoriesList = [];
    const serviceCategoriesSnapshot = await getDocs(
      collection(database, "serviceCategories")
    );
    serviceCategoriesSnapshot.forEach((doc) =>
      serviceCategoriesList.push(doc.data())
    );
    const servicesSnapshot = await getDocs(collection(database, "services"));
    const querySnapshot = await getDocs(collection(database, "packages"));
    servicesSnapshot.forEach((doc) => servicesList.push(doc.data()));
    querySnapshot.forEach((doc) => packagesList.push(doc.data()));
    setServiceCategories(serviceCategoriesList);
    setServices(servicesList);
    setPackages(packagesList);
  }, []);

  useEffect(() => {
    getServicesAndPackages();
  }, [getServicesAndPackages]);

  const handleNext = () => {
    if (!isShowPrice) setShowPrice(true);
    else {
      setShowPrice(false);
      setActiveStep(activeStep + 1);
    }
  };

  const handleFinish = async () => {
    const uid = await localStorage.getItem("uid");
    updateDoc(doc(database, "users", uid), {
      services: {
        internet: selectedInternet.title || "",
        tv: selectedTv.title || "",
        solar: solarprovider.title || "",
        pest: selectedPest.title || "",
        security: securityprovider.title || "",
      },
      packages: {
        tv: tvPackage,
        pest: pestPackage,
        solar: solarPackage,
        internet: internetPackage,
        security: securityPackage,
      },
    })
      .then(() => navigate("/closing"))
      .catch((error) => {
        Swal.fire({
          text: error,
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getUser = useCallback(async () => {
    setLoading(true);
    const uid = await localStorage.getItem("uid");
    if (uid) {
      const docSnap = await getDoc(doc(database, "users", uid));
      if (docSnap.exists()) setFname(docSnap.data().fname);
    } else navigate("/");
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  let securityproviders = services.filter((e) => e.category === "Security");
  let solarProviders = services.filter((e) => e.category === "solar");
  let internet = services.filter((e) => e.category === "Internet");
  let pest = services.filter((e) => e.category === "pestControl");
  let tv = services.filter((e) => e.category === "tv");

  let tvImg = serviceCategories.find((e) => e.title === "tv");
  let solarImg = serviceCategories.find((e) => e.title === "solar");
  let pestImg = serviceCategories.find((e) => e.title === "pestControl");
  let securityImg = serviceCategories.find((e) => e.title === "Security");
  let internetImg = serviceCategories.find((e) => e.title === "Internet");

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <>
        <Topnav />
        <div id="services">
          {isShowPrice ? (
            <Pricing
              packages={packages}
              services={services}
              tvPackage={tvPackage}
              handleNext={handleNext}
              activeStep={activeStep}
              handleBack={handleBack}
              selectedTv={selectedTv}
              pestPackage={pestPackage}
              handleFinish={handleFinish}
              solarPackage={solarPackage}
              setTvPackage={setTvPackage}
              selectedPest={selectedPest}
              solarprovider={solarprovider}
              setPestPackage={setPestPackage}
              internetPackage={internetPackage}
              securityPackage={securityPackage}
              selectedInternet={selectedInternet}
              securityprovider={securityprovider}
              setInternetPackage={setInternetPackage}
              setSecurityPackage={setSecurityPackage}
              setSolarprovidersPackage={setSolarprovidersPackage}
            />
          ) : (
            <Box
              width={{ lg: "95%", md: "95%", sm: "95%", xs: "95%" }}
              marginTop={5}
            >
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <img
                  id="maddie"
                  alt="maddie"
                  src={maddie}
                  className="animate__animated animate__fadeIn"
                />
              </Row>
              <Box
                width={"100%"}
                marginTop={"20px"}
                alignItems="center"
                flexDirection={"row"}
                justifyContent={"center"}
              >
                {activeStep === 0 && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      textAlign="center"
                      color="text.primary"
                      fontFamily={"'Nunito Sans', sans-serif"}
                      fontSize={{ lg: 45, md: 35, sm: 25, xs: 18 }}
                      className="top-text animate__animated animate__fadeIn"
                      width={{ lg: "75%", md: "75%", sm: "100%", xs: "100%" }}
                    >
                      Hi {fname}, it's great to meet you. I'm Maddie. I'll be
                      your home service concierge. Ready to go?
                    </Typography>
                    <br />
                    <Typography
                      textAlign="center"
                      color="text.primary"
                      fontSize={{ lg: 25, md: 20, sm: 15, xs: 15 }}
                      className="animate__animated animate__fadeIn"
                    >
                      Here are some of the great services we will be checking
                      out.
                    </Typography>
                    <Typography
                      color="text.primary"
                      textAlign={"center"}
                      fontSize={{ lg: 24, md: 20, sm: 15, xs: 15 }}
                      className="animate__animated animate__fadeIn"
                    >
                      HX can save you money and time with every home service!
                    </Typography>
                    <br />
                    <br />
                    <Button
                      onClick={() => setActiveStep(activeStep + 1)}
                      className="animate__animated animate__fadeIn"
                      variant="contained"
                      style={{
                        padding: "10px  40px 10px 40px",
                        borderRadius: "10px",
                        backgroundColor: "black",
                        color: "white",
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontWeight: "bold",
                        textTransform: "none",
                        fontSize: "15px",
                      }}
                    >
                      Let's do this!
                    </Button>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box
                    display={"flex"}
                    alignItems="center"
                    flexDirection="column"
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight={600}
                      textAlign="center"
                      color="text.primary"
                      fontFamily={"'noto-sans-bold', sans-serif"}
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 15 }}
                    >
                      Let's start with internet. Which <br></br>provider would
                      you prefer?
                    </Typography>
                    <br />
                    <Row className="marginTopOnRow2">
                      {internet.map((item, index) => {
                        return (
                          <Card
                            key={index}
                            className="animate__animated animate__fadeIn"
                            id={
                              item.title === selectedInternet.title
                                ? "selected-card-internet"
                                : "card-internet"
                            }
                            onClick={() => setInternet(item)}
                          >
                            <img
                              src={item.image}
                              id="card-services-internet"
                              alt="internet"
                            />
                          </Card>
                        );
                      })}
                    </Row>
                    <ServiceButton
                      handleSkip={handleSkip}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      state={selectedInternet}
                      handleFinish={handleFinish}
                    />
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box
                    display={"flex"}
                    textAlign="center"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight={600}
                      textAlign="center"
                      color="text.primary"
                      fontFamily={"'noto-sans-bold', sans-serif"}
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 15 }}
                    >
                      Now TV. Lets get you set up with TV.
                    </Typography>
                    <Row style={{ marginTop: "80px" }}>
                      {tv.map((item, index) => (
                        <Card
                          key={index}
                          className="animate__animated animate__fadeIn"
                          id={
                            item.title === selectedTv.title
                              ? "selected-card-internet"
                              : "card-internet"
                          }
                          onClick={() => setTv(item)}
                        >
                          <img
                            src={item.image}
                            id="card-services-internet"
                            alt="services-logos"
                          />
                        </Card>
                      ))}
                    </Row>
                    <ServiceButton
                      state={selectedTv}
                      handleSkip={handleSkip}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      handleFinish={handleFinish}
                    />
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box
                    justifyContent={"center"}
                    alignItems="center"
                    display={"flex"}
                    flexDirection="column"
                  >
                    <Typography
                      fontWeight={600}
                      textAlign="center"
                      color="text.primary"
                      fontFamily={"'noto-sans-bold', sans-serif"}
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 15 }}
                    >
                      It's so important to have home security, <br></br>which
                      provider would you like?
                    </Typography>
                    <Row
                      className="marginTopOnRow2"
                      style={{ marginTop: "30px" }}
                    >
                      {securityproviders.map((item, index) => (
                        <Card
                          key={index}
                          className="animate__animated animate__fadeIn"
                          id={
                            item.title === securityprovider.title
                              ? "selected-card-internet"
                              : "card-internet"
                          }
                          onClick={() => setSecurityprovider(item)}
                        >
                          <img
                            src={item.image}
                            id="card-security-img"
                            alt="sercurity"
                          />
                        </Card>
                      ))}
                    </Row>
                    <ServiceButton
                      handleSkip={handleSkip}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      state={securityprovider}
                      handleFinish={handleFinish}
                    />
                  </Box>
                )}
                {activeStep === 4 && (
                  <Box
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    display={"flex"}
                    flexDirection="column"
                  >
                    <Typography
                      fontWeight={600}
                      textAlign="center"
                      color="text.primary"
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 15 }}
                      fontFamily={"'noto-sans-bold', sans-serif"}
                    >
                      Keep those pest out! Which provider do you prefer?
                    </Typography>
                    <Row style={{ marginTop: "30px" }}>
                      {pest.map((item, index) => (
                        <Card
                          key={index}
                          className="animate__animated animate__fadeIn"
                          id={
                            item.title === selectedPest.title
                              ? "selected-card-internet"
                              : "card-internet"
                          }
                          onClick={() => setPest(item)}
                        >
                          <img
                            src={item.image}
                            id="card-pest-internet"
                            alt="services-logos"
                          />
                        </Card>
                      ))}
                    </Row>
                    <ServiceButton
                      handleSkip={handleSkip}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      state={selectedPest}
                      handleFinish={handleFinish}
                      handleNext={handleNext}
                    />
                  </Box>
                )}
                {activeStep === 5 && (
                  <Box
                    justifyContent={"center"}
                    alignItems="center"
                    display={"flex"}
                    flexDirection="column"
                  >
                    <Typography
                      color="text.primary"
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 15 }}
                      fontFamily={"'noto-sans-bold', sans-serif"}
                      fontWeight={600}
                      textAlign="center"
                    >
                      Let's talk clean energy. Which solar provider would you
                      prefer?
                    </Typography>
                    <Row
                      className="marginTopOnRow3"
                      style={{ marginTop: "30px" }}
                    >
                      {solarProviders.map((item, index) => (
                        <Card
                          key={index}
                          className="animate__animated animate__fadeIn"
                          id={
                            item.title === solarprovider.title
                              ? "selected-card-internet"
                              : "card-internet"
                          }
                          onClick={() => setSolarproviders(item)}
                        >
                          <img
                            src={item.image}
                            id="card-pest-internet"
                            alt="services-logos"
                          />
                        </Card>
                      ))}
                    </Row>
                    <ServiceButton
                      handleSkip={handleSkip}
                      activeStep={activeStep}
                      handleBack={handleBack}
                      state={solarprovider}
                      handleFinish={handleFinish}
                      handleNext={handleNext}
                    />
                  </Box>
                )}
                {activeStep === 6 && (
                  <Box
                    display={"flex"}
                    alignItems="center"
                    flexDirection="column"
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight={600}
                      textAlign="center"
                      color="text.primary"
                      fontSize={{ lg: 35, md: 20, sm: 15, xs: 19 }}
                      fontFamily={"'noto-sans-bold', sans-serif"}
                    >
                      Congrats {fname} You're all set!
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="text.primary"
                      fontSize={{ lg: 28, md: 20, sm: 15, xs: 15 }}
                    >
                      I will get to work and text you with all of the info on
                      your selections and next steps.
                    </Typography>
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "30px",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        style={{
                          width: "18%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card
                          className="animate__animated animate__fadeIn"
                          id={"last-card-services"}
                        >
                          <img
                            id="card-services-images"
                            src={internetImg.image}
                            alt="services-logos"
                          />
                          <Typography
                            fontSize={{ lg: 15, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Internet
                          </Typography>
                        </Card>

                        {selectedInternet?.image ? (
                          <img
                            src={selectedInternet?.image}
                            id="card-services-images"
                            alt="services-logos"
                          />
                        ) : (
                          <Typography
                            marginTop={"30px"}
                            fontSize={{ lg: 14, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Service Not Selected
                          </Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "18%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card
                          className="animate__animated animate__fadeIn"
                          id={"last-card-services"}
                        >
                          <img
                            id="card-services-images"
                            alt="services-logos"
                            src={tvImg.image}
                          />
                          <Typography
                            fontSize={{ lg: 15, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Tv
                          </Typography>
                        </Card>
                        {selectedTv?.image ? (
                          <img
                            id="card-services-images"
                            src={selectedTv?.image}
                            alt="services-logos"
                          />
                        ) : (
                          <Typography
                            fontSize={{ lg: 14, md: 12, sm: 12, xs: 12 }}
                            marginTop={"30px"}
                            textAlign="center"
                          >
                            Service Not Selected
                          </Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "18%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card
                          className="animate__animated animate__fadeIn"
                          id={"last-card-services"}
                        >
                          <img
                            id="card-services-images"
                            src={securityImg.image}
                            alt="services-logos"
                          />
                          <Typography
                            fontSize={{ lg: 15, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Security
                          </Typography>
                        </Card>
                        {securityprovider?.image ? (
                          <img
                            src={securityprovider?.image}
                            id="card-services-images"
                            alt="services-logos"
                          />
                        ) : (
                          <Typography
                            marginTop={"30px"}
                            fontSize={{ lg: 14, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Service Not Selected
                          </Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "18%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card
                          className="animate__animated animate__fadeIn"
                          id={"last-card-services"}
                        >
                          <img
                            id="card-services-images"
                            alt="services-logos"
                            src={pestImg.image}
                          />
                          <Typography
                            fontSize={{ lg: 15, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Pest
                          </Typography>
                        </Card>
                        {selectedPest?.image ? (
                          <img
                            src={selectedPest?.image}
                            id="card-services-images"
                            alt="services-logos"
                          />
                        ) : (
                          <Typography
                            marginTop={"30px"}
                            fontSize={{ lg: 14, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Service Not Selected
                          </Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "18%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Card
                          className="animate__animated animate__fadeIn"
                          id={"last-card-services"}
                        >
                          <img
                            id="card-services-images"
                            src={solarImg.image}
                            alt="services-logos"
                          />
                          <Typography
                            fontSize={{ lg: 15, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Solar
                          </Typography>
                        </Card>
                        {solarprovider?.image ? (
                          <img
                            src={solarprovider?.image}
                            id="card-services-images"
                            alt="services-logos"
                          />
                        ) : (
                          <Typography
                            marginTop={"30px"}
                            fontSize={{ lg: 14, md: 12, sm: 12, xs: 12 }}
                            textAlign="center"
                          >
                            Service Not Selected
                          </Typography>
                        )}
                      </Box>
                    </Row>
                    <br />
                    <Button
                      variant="contained"
                      onClick={handleFinish}
                      style={{
                        width: "170px",
                        height: "45px",
                        borderRadius: "10px",
                        backgroundColor: "black",
                        marginBottom: "20px",
                        color: "white",
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      Save & Finish
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </div>
      </>
    );
  }
}

export default Services;
