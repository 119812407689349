import './Dashboard.css';
import React from 'react';
import Topnav from '../components/Topnav';
import logo from '../assets/hxLogo.png';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from '@mui/material';

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <>
      <Topnav />
      <div className="coverImg">
        <Container
          maxWidth="xl"
          className="dashboard-main-container animate__animated animate__fadeIn "
        >
          <div className="dashboardOpacity" />
          <div className="dashboardContainer">
            <img
              src={logo}
              className="dashboard-logo animate__animated animate__flip"
              style={{ animationDelay: '6s' }}
              alt="dashboard logo"
            />

            <div className="dashboard-heading">
              Manage your all services quickly and easily with HX Home Services
            </div>

            <Button
              className="animate__animated animate__swing"
              onClick={() => navigate('/services')}
              variant="contained"
              style={{
                backgroundColor: '#01B5E7',
                animationDelay: '4s',
                color: 'white',
                width: '200px',
                height: '40px',
              }}
            >
              Select Services
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}
