import {
  Box,
  Menu,
  AppBar,
  Avatar,
  Tooltip,
  Toolbar,
  MenuItem,
  Container,
  IconButton,
  Typography,
} from '@mui/material';
import * as React from 'react';
import logo from '../assets/hxLogo.png';
import { signOut, auth } from '../db/firebase';
import { useNavigate } from 'react-router-dom';

const pages = [
  { display_name: 'Dashboard', route: '/dashboard' },
  { display_name: 'Services', route: '/services' },
  // { display_name: "Invite Users", route: "/invite" },
];

const Topnav = () => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    localStorage.removeItem('uid');
    signOut(auth)
      .then(() => navigate('/'))
      .catch((error) => {
        console.log('🚀 ~ file: Topnav.jsx ~ line 41 ~ logout ~ error', error);
        navigate('/');
      });
  };

  return (
    <AppBar
      color="inherit"
      position="static"
      style={{ boxShadow: 'none', borderBottom: '1px solid gainsboro' }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{ justifyContent: 'space-between', padding: '0 20px' }}
        >
          <img
            src={logo}
            alt="company logo"
            style={{ height: 40, marginRight: 20 }}
          />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => navigate(page.route)}>
                  <Typography textAlign="center">
                    {page.display_name}
                  </Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topnav;
