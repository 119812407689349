import {
  doc,
  auth,
  getDoc,
  database,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "../db/firebase";
import "./auth.scss";
import Swal from "sweetalert2";
import { styled } from "@mui/system";
import logo from "../assets/hxLogo.png";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Typography, TextField, CircularProgress } from "@mui/material";

const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#01B5E7",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#01B5E7",
    },
  },
}));

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");

  const checkUser = React.useCallback(async () => {
    setLoading(true);
    let googleUid = await localStorage.getItem("googleUid");
    if (googleUid) navigate("/welcome");
    else navigate("/dashboard");
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user?.providerData[0]?.providerId === "google.com") {
          checkUser();
        } else navigate("/dashboard");
      } else {
        setLoading(false);
      }
    });
  }, [navigate, checkUser]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email or password is empty",
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password)
          .then(async (user) => {
            getDoc(doc(database, "users", user.user.uid)).then(async (doc) => {
              if (doc.exists()) {
                await localStorage.setItem("uid", user.user.uid);
                navigate("/dashboard");
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "User not found",
                });
              }
            });
          })
          .catch((error) => {
            Swal.fire({ text: error, icon: "error", title: "Oops..." });
          });
      }
    } catch (e) {
      Swal.fire({ text: e, icon: "error", title: "Oops..." });
    }
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        let docSnap = await getDoc(doc(database, "users", result.user.uid));
        if (docSnap.exists()) {
          await localStorage.setItem("uid", result.user.uid);
          navigate("/dashboard");
        } else {
          let obj = {
            uid: result.user.uid,
            email: result.user.email,
            userImg: result.user.photoURL,
            fname: result.user.displayName.split(" ")[0],
            lname: result.user.displayName.split(" ")[1] || "",
          };
          await localStorage.setItem("googleUid", JSON.stringify(obj));
          navigate("/welcome");
        }
      })
      .catch((error) => {
        Swal.fire({ text: error, icon: "error", title: "Oops..." });
      });
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <section>
        <div className="box">
          <div className="square" id="square0" />
          <div className="square" id="square1" />
          <div className="square" id="square2" />
          <div className="square" id="square3" />
          <div className="square" id="square4" />
          <div className="square" id="square5" />
          <div className="container">
            <div className="form">
              <div style={{ textAlign: "center" }}>
                <img src={logo} alt="logo" width={120} />
                <h3>Login To Your Account</h3>
              </div>
              <CssTextField
                fullWidth
                value={email}
                variant="outlined"
                label="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                style={{ color: "white", marginBottom: "20px" }}
              />
              <CssTextField
                fullWidth
                value={password}
                label="Password"
                type={"password"}
                variant="outlined"
                style={{ color: "white" }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="linksRow">
                <p>
                  Forgot password? <a href="/register">Click Here</a>
                </p>
                <p>
                  Don't have an account <a href="/register">Sign up</a>
                </p>
              </div>
              <Button
                variant="contained"
                onClick={handleLogin}
                fullWidth
                style={{
                  backgroundColor: "#01B5E7",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Login
              </Button>
              <Typography
                variant="h4"
                style={{
                  color: "gray",
                  fontSize: "18px",
                  margin: "20px 0",
                  textAlign: "center",
                }}
              >
                ---------------- OR ----------------
              </Typography>
              <GoogleButton onClick={googleSignIn} style={{ margin: "auto" }} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LoginPage;
