import {
  Stack,
  Button,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import "./welcome.scss";
import Swal from "sweetalert2";
import { styled } from "@mui/system";
import { Row, Col } from "reactstrap";
import logo from "../assets/avatar.svg";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { doc, getDocs, database, collection, setDoc } from "../db/firebase";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#01B5E7",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#01B5E7",
    },
  },
}));

function RegisterPage() {
  const navigate = useNavigate();

  const [uid, setUid] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [address, setAddress] = useState("");
  const [builder, setBuilder] = useState("");
  const [builders, setBuilders] = useState([]);
  const [date, setDate] = useState(new Date());
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");

  const checkUser = React.useCallback(async () => {
    setLoading(true);
    let googleData = await localStorage.getItem("googleUid");
    let uid = await localStorage.getItem("uid");
    if (uid) navigate("/dashboard");
    if (googleData) getUser(googleData);
    else navigate("/");
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    getBuilders();
    checkUser();
  }, [checkUser]);

  const getUser = async (googleData) => {
    const userData = JSON.parse(googleData);
    setFirstName(userData.fname);
    setUserImg(userData.userImg);
    setLastName(userData.lname);
    setEmail(userData.email);
    setUid(userData.uid);
  };

  const getBuilders = async () => {
    const builder = [];
    const querySnapshot = await getDocs(collection(database, "builders"));
    querySnapshot.forEach((doc) => {
      let businessName = `${doc.data().fname} ${doc.data().lname}`;
      if (doc.data().businessName) builder.push({ ...doc.data(), id: doc.id });
      else builder.push({ ...doc.data(), businessName, id: doc.id });
    });
    setBuilders(builder);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!phone || !address || !city || !state || !zip) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill out all fields",
        });
      } else {
        setLoad(true);
        let builderId = builders.length
          ? builders.find((e) => e.businessName === builder).id
          : null;
        setDoc(doc(database, "users", uid), {
          zip,
          uid,
          city,
          state,
          address,
          userImg,
          phoneNumber: phone,
          builder: builderId,
          createdAt: Date.now(),
          houseCompletingDate: date,
        })
          .then(async () => {
            setLoad(false);
            await localStorage.removeItem("googleUid");
            await localStorage.setItem("uid", uid);
            await fetch(
              "https://us-central1-fir-auth-1d4b6.cloudfunctions.net/builders/welcomeMail",
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify({
                  email,
                  username: `${firstName} ${lastName}`,
                }),
              }
            );
            navigate("/dashboard");
          })
          .catch((error) => {
            setLoad(false);
            Swal.fire({ text: error, icon: "error", title: "Oops..." });
          });
      }
    } catch (e) {
      setLoad(false);
      Swal.fire({ text: e, icon: "error", title: "Oops..." });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className="welcomeContainer">
        <div className="welcomebox">
          <div className="square" id="square0" />
          <div className="square" id="square1" />
          <div className="square" id="square2" />
          <div className="square" id="square3" />
          <div className="square" id="square4" />
          <div className="square" id="square5" />
          <div className="contain">
            <div className="form2">
              <div style={{ textAlign: "center" }}>
                <img src={logo} alt="logo" />
                <h5>Welcome To HX</h5>
                <h6>
                  Let's start with creating your profile!
                  <br />
                  You can complete your profile and continue using services.
                </h6>
              </div>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <CssTextField
                    disabled
                    fullWidth
                    value={firstName}
                    variant="outlined"
                    label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Col>
                <Col>
                  <CssTextField
                    disabled
                    fullWidth
                    value={lastName}
                    variant="outlined"
                    label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <CssTextField
                    disabled
                    fullWidth
                    value={email}
                    variant="outlined"
                    label="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col>
                  <CssTextField
                    fullWidth
                    value={phone}
                    type={"number"}
                    variant="outlined"
                    label="Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "15px" }}
                >
                  <CssTextField
                    fullWidth
                    value={address}
                    label="Address"
                    variant="outlined"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "15px" }}
                >
                  <CssTextField
                    fullWidth
                    value={city}
                    label="City"
                    variant="outlined"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "15px" }}
                >
                  <CssTextField
                    fullWidth
                    value={state}
                    label="State"
                    variant="outlined"
                    onChange={(e) => setState(e.target.value)}
                  />
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "15px" }}
                >
                  <CssTextField
                    fullWidth
                    label="Zip"
                    value={zip}
                    variant="outlined"
                    onChange={(e) => setZip(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <TextField
                    select
                    fullWidth
                    value={builder}
                    label="Select Builder"
                    onChange={(e) => setBuilder(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    {builders.map((option) => (
                      <MenuItem
                        key={option.businessName}
                        value={option.businessName}
                      >
                        {option.businessName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
                <Col>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        value={date}
                        inputFormat="MM/dd/yyyy"
                        label="House Completion Date"
                        onChange={(e) => setDate(e.target.value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Col>
              </Row>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                style={{ color: "white", backgroundColor: "#01B5E7" }}
              >
                {load ? <CircularProgress /> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
