import React from 'react';
import './closingPage.css';
import Typed from 'react-typed';
import { Row } from 'reactstrap';
import Topnav from '../components/Topnav';
import checked from '../assets/Checked.png';
import { useNavigate } from 'react-router-dom';
import maddie from '../assets/unyte-maddie.png';
import { Button, Box } from '@mui/material';

function ClosingPage() {
  const navigate = useNavigate();

  return (
    <>
      <Topnav />
      <Box
        marginTop={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={maddie}
            id="maddie"
            alt="maddie"
            className="animate__animated animate__fadeInDown"
          />
        </Row>
        <Box
          alignItems="center"
          flexDirection={'row'}
          justifyContent={'center'}
        >
          <div id="check-container">
            <img
              src={checked}
              id="checked"
              alt="checked"
              className="animate__animated animate__heartBeat"
            />
            <Typed
              typeSpeed={20}
              showCursor={false}
              className="thanks-heading"
              strings={['Thank you for choosing HX!']}
            />
            <Button
              variant="contained"
              className="animate__animated animate__swing"
              style={{
                color: 'white',
                width: '220px',
                height: '50px',
                marginTop: '30px',
                borderRadius: '10px',
                backgroundColor: 'black',
                fontFamily: "'noto-sans-bold', sans-serif",
              }}
              onClick={() => navigate('/dashboard')}
            >
              Go To HX Home
            </Button>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ClosingPage;
