import "./button.css";
import React from "react";
import { Button, Box } from "@mui/material";

export default function ServiceButton({
  handleFinish,
  activeStep,
  handleBack,
  handleSkip,
  handleNext,
  state,
}) {
  return (
    <Box
      display="flex"
      marginTop="40px"
      flexDirection="row"
      justifyContent="center"
      className="animate__animated animate__bounceInRight"
    >
      <Button
        className="butt"
        variant="contained"
        onClick={handleBack}
        disabled={activeStep === 0}
      >
        Back
      </Button>
      <Button
        className="butt"
        variant="contained"
        disabled={state === ""}
        onClick={activeStep === 6 ? handleFinish : handleNext}
        style={{ backgroundColor: state === "" ? "gray" : "black" }}
      >
        Next
      </Button>
      <Button
        className="butt"
        variant="contained"
        onClick={handleSkip}
        disabled={activeStep === 0}
      >
        Skip
      </Button>
    </Box>
  );
}
