import {
  doc,
  auth,
  setDoc,
  getDoc,
  getDocs,
  database,
  collection,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from '../db/firebase';
import './auth.scss';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import { Stack } from '@mui/material';
import { Row, Col } from 'reactstrap';
import logo from '../assets/hxLogo.png';
import { useNavigate } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import React, { useEffect, useState, useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const CssTextField = styled(TextField)(() => ({
  '& label.Mui-focused': {
    color: '#01B5E7',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#01B5E7',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#01B5E7',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#01B5E7',
    },
  },
}));

function RegisterPage() {
  const navigate = useNavigate();

  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [builder, setBuilder] = useState('');
  const [builders, setBuilders] = useState([]);
  const [lastName, setLastName] = useState('');
  const [date, setDate] = useState(new Date());
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [builderDisable, setBuilderDisable] = useState(false);

  const checkUser = React.useCallback(async () => {
    let googleUid = await localStorage.getItem('googleUid');
    if (googleUid) navigate('/welcome');
    else navigate('/dashboard');
  }, [navigate]);

  const autoFillBuilders = useCallback((list) => {
    if (window.location.search && list.length) {
      let params = window.location?.search?.split('&');
      let build = params[0]?.split('=')[1];
      let builderName = list.find((e) => e.uid === build);
      if (builderName) {
        setBuilder(builderName.buisnessName);
        setBuilderDisable(true);
      }
    }
  }, []);

  const getBuilders = useCallback(async () => {
    const builder = [];
    const querySnapshot = await getDocs(collection(database, 'builders'));
    querySnapshot.forEach((doc) => {
      let businessName = `${doc.data().fname} ${doc.data().lname}`;
      if (doc.data().businessName) builder.push({ ...doc.data(), uid: doc.id });
      else builder.push({ ...doc.data(), businessName, uid: doc.id });
    });
    autoFillBuilders(builder);
    setBuilders(builder);
  }, [autoFillBuilders]);

  useEffect(() => {
    getBuilders();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user?.providerData[0]?.providerId === 'google.com') {
          checkUser();
        } else navigate('/dashboard');
      }
    });
  }, [navigate, checkUser, getBuilders]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !email ||
        !password ||
        !firstName ||
        !lastName ||
        !phone ||
        !address ||
        !city ||
        !state ||
        !zip
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill out all fields in correct format',
        });
      } else {
        let builderId = builders.find((e) => e.businessName === builder);
        await createUserWithEmailAndPassword(auth, email, password)
          .then((user) => {
            setDoc(doc(database, 'users', user.user.uid), {
              zip,
              city,
              state,
              email,
              address,
              userImg: null,
              lname: lastName,
              fname: firstName,
              phoneNumber: phone,
              createdAt: Date.now(),
              builder: builderId.uid,
              houseCompletingDate: date,
              company: builderId?.company
                ? builderId.company
                : builderId.businessName,
            })
              .then(async () => {
                await localStorage.setItem('uid', user.user.uid);
                fetch(
                  'https://us-central1-fir-auth-1d4b6.cloudfunctions.net/builders/welcomeMail',
                  {
                    method: 'POST',
                    headers: {
                      'Content-type': 'application/json;charset=UTF-8',
                      'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify({
                      email,
                      username: `${firstName} ${lastName}`,
                    }),
                  }
                );
                navigate('/dashboard');
              })
              .catch((error) => {
                Swal.fire({ text: error, icon: 'error', title: 'Oops...' });
              });
          })
          .catch((error) => {
            Swal.fire({ text: error, icon: 'error', title: 'Oops...' });
          });
      }
    } catch (e) {
      Swal.fire({ text: e, icon: 'error', title: 'Oops...' });
    }
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        let docSnap = await getDoc(doc(database, 'users', result.user.uid));
        if (docSnap.exists()) {
          await localStorage.setItem('uid', result.user.uid);
          navigate('/dashboard');
        } else {
          let obj = {
            uid: result.user.uid,
            email: result.user.email,
            userImg: result.user.photoURL,
            fname: result.user.displayName.split(' ')[0],
            lname: result.user.displayName.split(' ')[1] || '',
          };
          await localStorage.setItem('googleUid', JSON.stringify(obj));
          navigate('/welcome');
        }
      })
      .catch((error) => {
        Swal.fire({ text: error, icon: 'error', title: 'Oops...' });
      });
  };

  const handleBuilder = (e) => {
    setBuilder(e.target.value);
  };

  return (
    <section>
      <div className="box">
        <div className="square" id="square0" />
        <div className="square" id="square1" />
        <div className="square" id="square2" />
        <div className="square" id="square3" />
        <div className="square" id="square4" />
        <div className="square" id="square5" />
        <div className="container">
          <div className="form">
            <div style={{ textAlign: 'center' }}>
              <img src={logo} alt="logo" width={120} />
              <h4 className="formHead">Create Account</h4>
            </div>
            <Row style={{ marginBottom: '15px' }}>
              <Col>
                <CssTextField
                  fullWidth
                  value={firstName}
                  variant="outlined"
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
              <Col>
                <CssTextField
                  fullWidth
                  value={lastName}
                  variant="outlined"
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
              <Col>
                <CssTextField
                  fullWidth
                  value={email}
                  variant="outlined"
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col>
                <CssTextField
                  fullWidth
                  value={phone}
                  type={'number'}
                  variant="outlined"
                  label="Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={6} xs={6} style={{ marginBottom: '15px' }}>
                <CssTextField
                  fullWidth
                  value={address}
                  label="Address"
                  variant="outlined"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Col>
              <Col md={3} sm={6} xs={6} style={{ marginBottom: '15px' }}>
                <CssTextField
                  fullWidth
                  value={city}
                  label="City"
                  variant="outlined"
                  onChange={(e) => setCity(e.target.value)}
                />
              </Col>
              <Col md={3} sm={6} xs={6} style={{ marginBottom: '15px' }}>
                <CssTextField
                  fullWidth
                  value={state}
                  label="State"
                  variant="outlined"
                  onChange={(e) => setState(e.target.value)}
                />
              </Col>
              <Col md={3} sm={6} xs={6} style={{ marginBottom: '15px' }}>
                <CssTextField
                  fullWidth
                  label="Zip"
                  value={zip}
                  variant="outlined"
                  onChange={(e) => setZip(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '15px' }}>
              <Col>
                <TextField
                  select
                  fullWidth
                  value={builder}
                  label="Select Builder"
                  onChange={handleBuilder}
                  disabled={builderDisable}
                >
                  <MenuItem value={'Select'}>Select</MenuItem>
                  {builders.map((option, index) => (
                    <MenuItem key={index} value={option.businessName}>
                      {option.businessName}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack>
                    <DesktopDatePicker
                      value={date}
                      inputFormat="MM/dd/yyyy"
                      label="House Completion Date"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(target) =>
                        setDate(new Date(target).toLocaleDateString())
                      }
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
            </Row>
            <CssTextField
              fullWidth
              value={password}
              label="Password"
              type={'password'}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
            <p>
              Already have an account? <a href="/">Sign in</a>
            </p>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              style={{
                color: 'white',
                marginTop: '10px',
                backgroundColor: '#01B5E7',
              }}
            >
              Signup
            </Button>
            <Typography
              variant="h4"
              style={{
                color: 'gray',
                fontSize: '18px',
                margin: '20px 0',
                textAlign: 'center',
              }}
            >
              ---------------- OR ----------------
            </Typography>
            <GoogleButton onClick={googleSignIn} style={{ margin: 'auto' }} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterPage;
