import {
  Grid,
  Card,
  Container,
  CardHeader,
  CardContent,
  Typography,
  CssBaseline,
  GlobalStyles,
} from "@mui/material";
import "./price.css";
import * as React from "react";
import Typed from "react-typed";
import maddie from "../assets/unyte-maddie.png";
import StarIcon from "@mui/icons-material/StarBorder";

export default function Pricing({
  packages,
  activeStep,
  handleNext,
  selectedTv,
  selectedPest,
  setTvPackage,
  solarprovider,
  setPestPackage,
  selectedInternet,
  securityprovider,
  setInternetPackage,
  setSecurityPackage,
  setSolarprovidersPackage,
}) {
  let tv = packages.filter((e) => e.category === "tv");
  let pest = packages.filter((e) => e.category === "pestControl");
  let internet = packages.filter((e) => e.category === "Internet");
  let solarProviders = packages.filter((e) => e.category === "solar");
  let securityproviders = packages.filter((e) => e.category === "Security");

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{
          pt: 8,
          pb: 3,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img src={maddie} id="maddie" alt="maddie" />
        {activeStep === 1 && (
          <Typed
            typeSpeed={20}
            showCursor={false}
            className="service-price-heading"
            strings={["Great choice! What selection fits you the best?"]}
          />
        )}
        {activeStep === 2 && (
          <Typed
            typeSpeed={20}
            showCursor={false}
            className="service-price-heading"
            strings={["What do you want to watch?"]}
          />
        )}
        {activeStep === 3 && (
          <Typed
            typeSpeed={20}
            showCursor={false}
            className="service-price-heading"
            strings={["Ok! Which of these would you like?"]}
          />
        )}
        {activeStep === 4 && (
          <Typed
            typeSpeed={20}
            showCursor={false}
            className="service-price-heading"
            strings={["How often would you like pest protection?"]}
          />
        )}
        {activeStep === 5 && (
          <Typed
            typeSpeed={20}
            showCursor={false}
            className="service-price-heading"
            strings={["What is most important with your solar selection?"]}
          />
        )}
      </Container>
      {/* End hero unit */}

      {activeStep === 1 && (
        <Card id={"card-internet-img"}>
          <img
            className="animate__animated animate__fadeIn     "
            style={{ animationDelay: ".6s" }}
            src={selectedInternet?.image}
            id="card-services-internet"
            alt="internet"
          />
        </Card>
      )}
      {activeStep === 2 && (
        <Card id={"card-internet-img"}>
          <img
            className="animate__animated animate__fadeIn   "
            style={{ animationDelay: ".6s" }}
            src={selectedTv?.image}
            id="card-services-internet"
            alt="internet"
          />
        </Card>
      )}
      {activeStep === 3 && (
        <Card id={"card-internet-img"}>
          <img
            className="animate__animated animate__fadeIn    "
            style={{ animationDelay: ".6s" }}
            src={securityprovider?.image}
            id="card-services-internet"
            alt="internet"
          />
        </Card>
      )}
      {activeStep === 4 && (
        <Card id={"card-internet-img"}>
          <img
            className="animate__animated animate__backInUp"
            style={{ animationDelay: ".6s" }}
            src={selectedPest?.image}
            id="card-services-internet"
            alt="internet"
          />
        </Card>
      )}
      {activeStep === 5 && (
        <Card id={"card-internet-img"}>
          <img
            className="animate__animated animate__backInUp"
            style={{ animationDelay: ".6s" }}
            src={solarprovider?.image}
            id="card-services-internet"
            alt="internet"
          />
        </Card>
      )}

      <Container
        maxWidth="md"
        component="main"
        style={{ paddingBottom: "30px" }}
      >
        <Grid container spacing={5} justifyContent={"center"} mt="0">
          {activeStep === 1 &&
            internet.map((tier) => (
              <Grid
                item
                style={{ cursor: "pointer" }}
                sm={6}
                md={4}
                xs={12}
                id="test"
                key={tier.title}
                onClick={() => {
                  setInternetPackage(tier.description);
                  handleNext();
                }}
                className="animate__animated animate__fadeIn"
              >
                <Card
                  sx={{
                    height: "100%",
                    position: "relative",
                    boxShadow: "0 0 16px silver",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />

                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={{ lg: 18, md: 17, sm: 17, xs: 17 }}
                      component="h1"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          {activeStep === 2 &&
            tv.map((tier) => (
              <Grid
                item
                style={{ cursor: "pointer" }}
                className="animate__animated animate__fadeIn"
                key={tier.title}
                xs={12}
                onClick={() => {
                  setTvPackage(tier.description);
                  handleNext();
                }}
                sm={6}
                md={4}
              >
                <Card
                  sx={{
                    height: "100%",
                    position: "relative",
                    boxShadow: "0 0 16px silver",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={{ lg: 18, md: 17, sm: 17, xs: 17 }}
                      component="h1"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          {activeStep === 3 &&
            securityproviders.map((tier) => (
              <Grid
                item
                style={{ cursor: "pointer" }}
                className="animate__animated animate__fadeIn"
                key={tier.title}
                xs={12}
                sm={6}
                onClick={() => {
                  setSecurityPackage(tier.description);
                  handleNext();
                }}
                md={4}
              >
                <Card
                  sx={{
                    height: "100%",
                    position: "relative",
                    boxShadow: "0 0 16px silver",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={{ lg: 18, md: 17, sm: 17, xs: 17 }}
                      component="h1"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          {activeStep === 4 &&
            pest.map((tier) => (
              <Grid
                item
                style={{ cursor: "pointer" }}
                className="animate__animated animate__fadeIn"
                key={tier.title}
                xs={12}
                sm={6}
                onClick={() => {
                  setPestPackage(tier.description);
                  handleNext();
                }}
                md={4}
              >
                <Card
                  sx={{
                    height: "100%",
                    position: "relative",
                    boxShadow: "0 0 16px silver",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={{ lg: 18, md: 17, sm: 17, xs: 17 }}
                      component="h1"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          {activeStep === 5 &&
            solarProviders.map((tier) => (
              <Grid
                item
                style={{ cursor: "pointer" }}
                className="animate__animated animate__fadeIn"
                key={tier.title}
                xs={12}
                onClick={() => {
                  setSolarprovidersPackage(tier.description);
                  handleNext();
                }}
                sm={6}
                md={4}
              >
                <Card
                  sx={{
                    height: "100%",
                    position: "relative",
                    boxShadow: "0 0 16px silver",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={{ lg: 18, md: 17, sm: 17, xs: 17 }}
                      component="h1"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
